// AUTHENTICATE
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

// DASHBOARD
export const INIT_DASHBOARD_START = 'INIT_DASHBOARD_START';
export const INIT_DASHBOARD_SUCCESS = 'INIT_DASHBOARD_SUCCESS';
export const INIT_DASHBOARD_FAIL = 'INIT_DASHBOARD_FAIL';

// REQUEST
export const FETCH_REQUEST_START = 'FETCH_REQUEST_START';
export const FETCH_REQUEST_SUCCESS = 'FETCH_REQUEST_SUCCESS';
export const FETCH_REQUEST_FAIL = 'FETCH_REQUEST_FAIL';

// REQUEST DETAIL
export const FETCH_REQUEST_DETAIL_START = 'FETCH_REQUEST_DETAIL_START';
export const FETCH_REQUEST_DETAIL_SUCCESS = 'FETCH_REQUEST_DETAIL_SUCCESS';
export const FETCH_REQUEST_DETAIL_FAIL = 'FETCH_REQUEST_DETAIL_FAIL';

export const FETCH_REQUEST_LOGS_START = 'FETCH_REQUEST_LOGS_START';
export const FETCH_REQUEST_LOGS_SUCCESS = 'FETCH_REQUEST_LOGS_SUCCESS';
export const FETCH_REQUEST_LOGS_FAIL = 'FETCH_REQUEST_LOGS_FAIL';

// REQUEST CREATE
export const SUBMIT_REQUEST_CREATE_START = 'SUBMIT_REQUEST_CREATE_START';
export const SUBMIT_REQUEST_CREATE_SUCCESS = 'SUBMIT_REQUEST_CREATE_SUCCESS';
export const SUBMIT_REQUEST_CREATE_FAIL = 'SUBMIT_REQUEST_CREATE_FAIL';

export const INIT_FORM_START = 'INIT_FORM_START';
export const INIT_FORM_SUCCESS = 'INIT_FORM_SUCCESS';
export const INIT_FORM_FAIL = 'INIT_FORM_FAIL';

// REQUEST EDIT
export const FETCH_REQUEST_EDIT_START = 'FETCH_REQUEST_EDIT_START';
export const FETCH_REQUEST_EDIT_SUCCESS = 'FETCH_REQUEST_EDIT_SUCCESS';
export const FETCH_REQUEST_EDIT_FAIL = 'FETCH_REQUEST_EDIT_FAIL';

export const SUBMIT_REQUEST_EDIT_START = 'SUBMIT_REQUEST_EDIT_START';
export const SUBMIT_REQUEST_EDIT_SUCCESS = 'SUBMIT_REQUEST_EDIT_SUCCESS';
export const SUBMIT_REQUEST_EDIT_FAIL = 'SUBMIT_REQUEST_EDIT_FAIL';

// REQUEST REIMBURSE FORM MODAL
export const INIT_REIMBURSE_FORM_START = 'INIT_REIMBURSE_FORM_START';
export const INIT_REIMBURSE_FORM_SUCCESS = 'INIT_REIMBURSE_FORM_SUCCESS';
export const INIT_REIMBURSE_FORM_FAIL = 'INIT_REIMBURSE_FORM_FAIL';

export const DELETE_REQUEST_REIMBURSE_START = 'DELETE_REQUEST_REIMBURSE_START';
export const DELETE_REQUEST_REIMBURSE_SUCCESS = 'DELETE_REQUEST_REIMBURSE_SUCCESS';
export const DELETE_REQUEST_REIMBURSE_FAIL = 'DELETE_REQUEST_REIMBURSE_FAIL';

export const SUBMIT_REQUEST_REIMBURSE_START = 'SUBMIT_REQUEST_REIMBURSE_START';
export const SUBMIT_REQUEST_REIMBURSE_SUCCESS = 'SUBMIT_REQUEST_REIMBURSE_SUCCESS';
export const SUBMIT_REQUEST_REIMBURSE_FAIL = 'SUBMIT_REQUEST_REIMBURSE_FAIL';

// REQUEST STATISTIC
export const FETCH_REQUEST_MASTER_START = 'FETCH_REQUEST_MASTER_START';
export const FETCH_REQUEST_MASTER_SUCCESS = 'FETCH_REQUEST_MASTER_SUCCESS';
export const FETCH_REQUEST_MASTER_FAIL = 'FETCH_REQUEST_MASTER_FAIL';

// MODAL
export const SHOW_MODAL_CONFIRM = 'SHOW_MODAL_CONFIRM';
export const HIDE_MODAL_CONFIRM = 'HIDE_MODAL_CONFIRM';

export const SHOW_MODAL_MORE_REVIEWERS = 'SHOW_MODAL_MORE_REVIEWERS';
export const HIDE_MODAL_MORE_REVIEWERS = 'HIDE_MODAL_MORE_REVIEWERS';

export const SHOW_MODAL_DELEGATE = 'SHOW_MODAL_DELEGATE';
export const HIDE_MODAL_DELEGATE = 'HIDE_MODAL_DELEGATE';

// REQUEST ACTION
export const SUBMIT_REQUEST_ACTION_START = 'SUBMIT_REQUEST_ACTION_START';
export const SUBMIT_REQUEST_ACTION_SUCCESS = 'SUBMIT_REQUEST_ACTION_SUCCESS';
export const SUBMIT_REQUEST_ACTION_FAIL = 'SUBMIT_REQUEST_ACTION_FAIL';

export const ADD_MORE_REVIEWERS_START = 'ADD_MORE_REVIEWERS_START';
export const ADD_MORE_REVIEWERS_SUCCESS = 'ADD_MORE_REVIEWERS_SUCCESS';
export const ADD_MORE_REVIEWERS_FAIL = 'ADD_MORE_REVIEWERS_FAIL';

export const SUBMIT_DELEGATION_START = 'SUBMIT_DELEGATION_START';
export const SUBMIT_DELEGATION_SUCCESS = 'SUBMIT_DELEGATION_SUCCESS';
export const SUBMIT_DELEGATION_FAIL = 'SUBMIT_DELEGATION_FAIL';

// REPORT
export const FETCH_REPORT_START = 'FETCH_REPORT_START';
export const FETCH_REPORT_SUCCESS = 'FETCH_REPORT_SUCCESS';
export const FETCH_REPORT_FAIL = 'FETCH_REPORT_FAIL';

// SETTING
export const EDIT_SETTING_START = 'EDIT_SETTING_START';
export const EDIT_SETTING_SUCCESS = 'EDIT_SETTING_SUCCESS';
export const EDIT_SETTING_FAIL = 'EDIT_SETTING_FAIL';

// PJ TAG
export const SAVE_PROJECT_TAG_START = 'SAVE_PROJECT_TAG_START';
export const SAVE_PROJECT_TAG_SUCCESS = 'SAVE_PROJECT_TAG_SUCCESS';
export const SAVE_PROJECT_TAG_FAIL = 'SAVE_PROJECT_TAG_FAIL';
