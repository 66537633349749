export const LANDING = '/';
export const LOGIN = '/login';
export const LOGIN_CALLBACK = '/login/callback';
export const LOGOUT_CALLBACK = '/logout/callback';
export const DASHBOARD = '/dashboard';

export const DASHBOARD_REQUEST = `${DASHBOARD}/request`;
export const DASHBOARD_REQUEST_DETAIL = `${DASHBOARD_REQUEST}/:id`;
export const DASHBOARD_REQUEST_CREATE = `${DASHBOARD_REQUEST}/create`;
export const DASHBOARD_REQUEST_EDIT = `${DASHBOARD_REQUEST}/:id/edit`;
export const DASHBOARD_REQUEST_ACTION_DETAIL = `${DASHBOARD_REQUEST}/:id/:action`;
export const DASHBOARD_REQUEST_REIMBURSE = `${DASHBOARD_REQUEST}/:id/reimburse`;
export const DASHBOARD_REPORT = `${DASHBOARD}/report`;
export const DASHBOARD_SETTING = `${DASHBOARD}/setting`;

export const CURRENT_EPOCH_TIME = '/v1/current-time/epoch';
