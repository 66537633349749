import auth from './auth';
import dashboard from './dashboard';
import request from './request';
import requestDetail from './requestDetail';
import requestCreate from './requestCreate';
import requestEdit from './requestEdit';
import requestAction from './requestAction';
import requestReimburse from './requestReimburse';
import requestMaster from './requestMaster';
import report from './report';
import setting from './setting';

export default {
  auth,
  dashboard,
  request,
  requestDetail,
  requestCreate,
  requestEdit,
  requestAction,
  requestReimburse,
  requestMaster,
  report,
  setting,
};
