export const loadUserProfile = () => {
  try {
    const serializedState = localStorage.getItem('userProfile');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const saveUserProfile = state => {
  try {
    if (state) {
      localStorage.setItem('userProfile', JSON.stringify(state));
    }
  } catch (error) {
    // Ignore write errors
  }
};
