export const ROLES = {
  ADMIN: 'ADMIN',
  FINANCER: 'FINANCER',
};

export const ACTOR_TYPES = {
  CREATOR: 'CREATOR',
  REVIEWER: 'REVIEWER',
  APPROVER: 'APPROVER',
  DELEGATOR: 'DELEGATOR',
  FINANCER: 'FINANCER',
  FIN_DELEGATOR: 'FIN-DELEGATOR',
  WATCHER: 'WATCHER',
  TRAVELLER: 'TRAVELLER',
};

export const ACTOR_STATUSES = {
  CANCELED: 'CANCELED',
  SUBMITTED: 'SUBMITTED',
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  DELEGATE: 'DELEGATE',
  REIMBURSING: 'REIMBURSING',
  REIMBURSED: 'REIMBURSED',
  REAPPROVE: 'REAPPROVE',
  REAPPROVED: 'REAPPROVED',
  REJECTING: 'REJECTING',
  CLOSED: 'CLOSED',
};
