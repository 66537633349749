import { ACTOR_STATUSES } from './user';

export const REQUEST_STATUSES = {
  CREATED: 'CREATED',
  SUBMITTED: 'SUBMITTED',
  RESUBMITTED: 'RESUBMITTED',
  RE_REVIEW: 'RE-REVIEW',
  CANCELED: 'CANCELED',
  REVIEWED: 'REVIEWED',
  DELEGATED: 'DELEGATED',
  DELEGATED_FINANCER: 'DELEGATED-FINANCER',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  REIMBURSING: 'REIMBURSING',
  REIMBURSED: 'REIMBURSED',
  REAPPROVE: 'REAPPROVE',
  REAPPROVED: 'REAPPROVED',
  DONE: 'DONE',
};

export const STATUS_COLORS = {
  PRIMARY: '#108ee9',
  SECONDARY: '#2db7f5',
  SUCCESS: '#87d068',
  DANGER: '#f50',
};

// Colors refer from: https://learnui.design/tools/data-color-picker.html#palette
export const DEPARTMENTS = {
  engineer: {
    key: 'engineer',
    color: '#665191',
    label: 'Engineer',
  },
  human_resource: {
    key: 'hr',
    color: '#a05195',
    label: 'Human Resource',
  },
  finance_and_administrator: {
    key: 'f&a',
    color: '#d45087',
    label: 'Finance & Administrator',
  },
  it_support: {
    key: 'it support',
    color: '#f95d6a',
    label: 'IT Support',
  },
  board_of_director: {
    key: 'bom',
    color: '#ff7c43',
    label: 'Board Of Director',
  },
  project_management: {
    key: 'project management',
    color: '#ffa600',
    label: 'Project Management',
  },
  other: {
    key: 'others',
    color: '#f0f0f0',
    label: 'Others',
  },
};

// List of legacy departments from HRMS
export const LEGACY_DEPARTMENTS = [
  { value: 'hr', display: 'Human Resource (Old Departments)' },
  { value: 'finance & admin', display: 'Finance & Administrator (Old Departments)' },
  { value: 'f&a', display: 'Finance & Administrator (Old Departments)' },
  { value: 'it support', display: 'IT Support (Old Departments)' },
  { value: 'bom', display: 'Board Of Director' },
  { value: 'engineer', display: 'Engineer (Old Departments)' },
  { value: 'project management', display: 'Project Management (Old Departments)' },
  { value: 'general affairs', display: 'General Affairs' },
  { value: 'talent', display: 'Talent' },
  { value: 'career development', display: 'Career Development' },
  { value: 'it & security', display: 'IT & Security' },
  { value: 'additional services', display: 'Additional Services' },
  { value: 'odc', display: 'ODC' },
  { value: 'dedicated team', display: 'Dedicated Team' },
  { value: 'sales', display: 'Sales' },
  { value: 'customer success', display: 'Customer Success' },
  { value: 'fixed-price', display: 'Fixed-Price' },
  { value: 'digital-solution', display: 'Digital Solutions' },
  { value: 'shopware-solution', display: 'Shopware Solutions' },
  { value: 'spryker-solution', display: 'Spryker Solutions' },
];

export const getDefaultWatchers = () => {
  return [];
};

export const PROJECT_TAG_COLORS = {
  0: {
    key: 0,
    color: '#665191',
  },
  1: {
    key: 1,
    color: '#a05195',
  },
  2: {
    key: 2,
    color: '#d45087',
  },
  3: {
    key: 3,
    color: '#f95d6a',
  },
  4: {
    key: 4,
    color: '#ff7c43',
  },
  5: {
    key: 5,
    color: '#ffa600',
  },
  other: {
    key: 'others',
    color: 'rgb(16, 142, 233)',
    name: 'Others',
  },
};

export const ACTIONS = {
  submit: {
    label: 'SUBMIT',
    key: ACTOR_STATUSES.SUBMITTED,
  },
  confirm: {
    label: 'CONFIRM',
    key: ACTOR_STATUSES.CONFIRMED,
  },
  cancel: {
    label: 'CANCEL',
    key: ACTOR_STATUSES.CANCELED,
  },
  approve: {
    label: 'APPROVE',
    key: ACTOR_STATUSES.APPROVED,
  },
  reject: {
    label: 'REJECT',
    key: ACTOR_STATUSES.REJECTED,
  },
  delegate: {
    label: 'DELEGATE',
    key: ACTOR_STATUSES.DELEGATE,
  },
  reapproved: {
    label: 'REAPPROVE',
    key: ACTOR_STATUSES.REAPPROVED,
  },
  done: {
    label: 'CLOSE',
    key: 'DONE',
  },
};

export const REQUEST_TYPE = {
  PURCHASE: 'PURCHASE',
  BUSINESSTRIP: 'BUSINESS TRIP',
};

export const EXPORT_TYPE = {
  EXCEL: 'EXCEL',
  PDF: 'PDF',
};
