import { calcExpiration } from 'utils/utility';
import { loadUserProfile } from 'utils/localStorage';
import userManager from 'utils/userManager';
import * as actionTypes from './actionTypes';
import { verifyEmployee } from '../../utils/authenticator';

export const authStart = () => ({ type: actionTypes.AUTH_START });

export const authSuccess = (token, userId, userProfile) => ({
  type: actionTypes.AUTH_SUCCESS,
  token,
  userId,
  userProfile,
});

export const authFail = error => ({
  type: actionTypes.AUTH_FAIL,
  error,
});

export const logout = () => {
  if (typeof localStorage !== 'undefined') {
    localStorage.clear();
  }
  if (typeof sessionStorage !== 'undefined') {
    // eslint-disable-next-line no-undef
    sessionStorage.clear();
  }
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

/**
 * @param {import('oidc-client').User} user
 */
export const auth = user => {
  return async dispatch => {
    dispatch(authStart());
    if (user) {
      localStorage.setItem('accessToken', user.access_token);
      localStorage.setItem('expiresDate', String(user.expires_at));
      localStorage.setItem('userProfile', JSON.stringify(user.profile));
      dispatch(authSuccess(user.access_token, user.profile.sub, user.profile));
      // eslint-disable-next-line no-use-before-define
      dispatch(checkAuthTimeout(calcExpiration(user.expires_at)));
    }
  };
};

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      userManager
        .signinSilent({ client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET })
        .then(async user => {
          try {
            const response = await verifyEmployee(user);

            dispatch(auth(response));
          } catch (error) {
            throw error;
          }
        })
        .catch(err => {
          console.log(`Error on checkAuthTimeout function: ${err}`);
          dispatch(logout());
        });
    }, expirationTime * 1000);
  };
};

export const getCurrentUser = () => dispatch => {
  const accessToken = localStorage.getItem('accessToken');
  if (window.location.pathname.startsWith('/login/callback')) {
    return;
  }
  if (!accessToken) {
    dispatch(logout());
  } else {
    const expiresDate = Number(localStorage.getItem('expiresDate'));
    const timeLeft = calcExpiration(expiresDate);
    if (timeLeft <= 0) {
      dispatch(checkAuthTimeout(0));
    } else {
      const userProfile = loadUserProfile();
      dispatch(authSuccess(accessToken, userProfile.sub, userProfile));
      dispatch(checkAuthTimeout(timeLeft));
    }
  }
};
