import { WebStorageStateStore, UserManager, Log } from 'oidc-client';
import { logout } from './authenticator';
import { getCurrentEpochTimeFromServer } from './utility';

/**
 * Log configuration
 *
 * @type {console | Console}
 */
Log.logger = console;
Log.level = process.env.NODE_ENV !== 'production' ? Log.DEBUG : Log.NONE;

/**
 * UserManagerSettings
 *
 * @type UserManagerSettings
 */
const userManagerConfig = {
  authority: process.env.REACT_APP_GOOGLE_AUTH_URL,
  client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
  redirect_uri: `${window.location.origin}/login/callback`,
  silent_redirect_uri: `${window.location.origin}/silent-renew`,
  post_logout_redirect_uri: `${window.location.origin}/logout/callback`,
  automaticSilentRenew: false,
  loadUserInfo: true,
  response_type: 'code',
  accessTokenExpiringNotificationTime: 30,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  scope: 'openid email profile',
  clockService: {
    getEpochTime() {
      return getCurrentEpochTimeFromServer();
    },
  },
  extraQueryParams: {
    access_type: 'offline', // enable refresh_token
    include_granted_scopes: true,
    prompt: 'consent', // always show prompt
  },
};

/**
 * CustomUserManager class
 *
 */
class CustomUserManager extends UserManager {
  constructor() {
    super(userManagerConfig);
  }

  /**
   * Customize this function in order to ignore getEndSessionEndpoint
   *
   * @param args
   * @return {Promise<SignoutRequest>}
   */
  async createSignoutRequest(args?: any) {
    try {
      await logout(localStorage.getItem('accessToken') || null);
    } catch (error) {
      console.log(`createSignoutRequest error: ${error}`);
    }

    return Promise.resolve({ url: userManagerConfig.post_logout_redirect_uri });
  }
}

/**
 *
 * @type {CustomUserManager}
 */
const userManager = new CustomUserManager(userManagerConfig);

/**
 * Add events
 */
// userManager.events.addUserLoaded(
//   user => {
//     store.dispatch(auth(user));
//   },
//   err => {
//     console.log(err);
//   },
// );

export default userManager;
