import * as actionTypes from 'store/actions/actionTypes';
import { updateObject } from 'utils/utility';

const initialState = {
  request: {},
  loading: true,
  error: null,
  submitted: false,
  formSubmitting: false,
  formError: null,
};

const fetchRequestEditlStart = state => {
  return updateObject(state, { error: null, loading: true });
};

const fetchRequestEditlSuccess = (state, action) => {
  return updateObject(state, {
    request: action.request,
    error: null,
    loading: false,
  });
};

const fetchRequestEditlFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const submitRequestEditStart = state => {
  return updateObject(state, { formError: null, formSubmitting: true });
};

const submitRequestEditSuccess = state => {
  return updateObject(state, {
    formError: null,
    formSubmitting: false,
    submitted: true,
  });
};

const submitRequestEditFail = (state, action) => {
  return updateObject(state, {
    formError: action.error,
    formSubmitting: false,
  });
};

const saveProjectTagStart = state => {
  return updateObject(state, { formError: null, formSubmitting: true });
};

const saveProjectTagSuccess = (state, action) => {
  return updateObject(state, { request: action.request, formError: null, formSubmitting: false });
};

const saveProjectTagFail = (state, action) => {
  return updateObject(state, { formError: action.error, formSubmitting: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REQUEST_EDIT_START:
      return fetchRequestEditlStart(state);
    case actionTypes.FETCH_REQUEST_EDIT_SUCCESS:
      return fetchRequestEditlSuccess(state, action);
    case actionTypes.FETCH_REQUEST_EDIT_FAIL:
      return fetchRequestEditlFail(state, action);
    case actionTypes.SUBMIT_REQUEST_EDIT_START:
      return submitRequestEditStart(state);
    case actionTypes.SUBMIT_REQUEST_EDIT_SUCCESS:
      return submitRequestEditSuccess(state);
    case actionTypes.SUBMIT_REQUEST_EDIT_FAIL:
      return submitRequestEditFail(state, action);
    case actionTypes.SAVE_PROJECT_TAG_START:
      return saveProjectTagStart(state);
    case actionTypes.SAVE_PROJECT_TAG_SUCCESS:
      return saveProjectTagSuccess(state, action);
    case actionTypes.SAVE_PROJECT_TAG_FAIL:
      return saveProjectTagFail(state, action);
    case '@@router/LOCATION_CHANGE':
      return initialState;
    default:
      return state;
  }
};

export default reducer;
