export default {
  apiUrl: process.env.REACT_APP_API_URL,
};

const googleConfig = {
  clientID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  clientSecret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
};

export { googleConfig };
