import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { createBrowserHistory as createHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { reducer as oidcReducer, loadUser } from 'redux-oidc';

import thunk from 'redux-thunk';
import throttle from 'lodash/throttle';
import userManager from 'utils/userManager';

import { saveUserProfile } from 'utils/localStorage';
import reducers from './reducers/reducers';

const history = createHistory();
const routeMiddleware = routerMiddleware(history);

const middlewares = [thunk, routeMiddleware];

let enhancer;
if (process.env.NODE_ENV === 'development') {
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  enhancer = composeEnhancers(applyMiddleware(thunk), applyMiddleware(...middlewares));
} else {
  enhancer = compose(
    applyMiddleware(thunk),
    applyMiddleware(...middlewares),
  );
}

const store = createStore(
  combineReducers({
    ...reducers,
    oidc: oidcReducer,
    router: connectRouter(history),
  }),
  enhancer,
);

loadUser(store, userManager);

store.subscribe(
  throttle(() => {
    saveUserProfile(store.getState().auth.userProfile);
  }, 1000),
);

export { store, history };
