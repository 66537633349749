import * as actionTypes from 'store/actions/actionTypes';
import { updateObject } from 'utils/utility';

const initialState = {
  requestDetail: {},
  loading: false,
  error: null,
  requestLogs: {},
  requestLogsLoading: false,
  requestLogsError: null,
};

const fetchRequestDetailStart = state => {
  return updateObject(state, { error: null, loading: true, requestDetail: {} });
};

const fetchRequestDetailSuccess = (state, action) => {
  return updateObject(state, {
    requestDetail: action.requestDetail,
    error: null,
    loading: false,
  });
};

const fetchRequestDetailFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const fetchRequestLogsStart = state => {
  return updateObject(state, {
    requestLogsLoading: true,
    requestLogsError: null,
  });
};

const fetchRequestLogsSuccess = (state, action) => {
  return updateObject(state, {
    requestLogs: action.data,
    requestLogsLoading: false,
  });
};

const fetchRequestLogsFail = (state, action) => {
  return updateObject(state, {
    requestLogsLoading: false,
    requestLogsError: action.error,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REQUEST_DETAIL_START:
      return fetchRequestDetailStart(state);
    case actionTypes.FETCH_REQUEST_DETAIL_SUCCESS:
      return fetchRequestDetailSuccess(state, action);
    case actionTypes.FETCH_REQUEST_DETAIL_FAIL:
      return fetchRequestDetailFail(state, action);
    case actionTypes.FETCH_REQUEST_LOGS_START:
      return fetchRequestLogsStart(state);
    case actionTypes.FETCH_REQUEST_LOGS_SUCCESS:
      return fetchRequestLogsSuccess(state, action);
    case actionTypes.FETCH_REQUEST_LOGS_FAIL:
      return fetchRequestLogsFail(state, action);
    case '@@router/LOCATION_CHANGE':
      return initialState;
    default:
      return state;
  }
};

export default reducer;
