import * as actionTypes from 'store/actions/actionTypes';
import { updateObject } from 'utils/utility';

const initialState = {
  requests: [],
  paginations: {
    currentPage: 1,
    itemPerPage: 20,
    totalItem: null,
    totalPage: null,
  },
  meta: {
    searchTitle: '',
    searchDate: [],
    searchStatus: [],
    searchRequestedBy: [],
  },
  error: null,
  loading: false,
};

export const fetchRequetStart = state => {
  return updateObject(state, { loading: true });
};

const fetchRequestSuccess = (state, action) => {
  const updatedState = {
    requests: action.requests,
    meta: action.meta,
    paginations: action.paginations,
    loading: false,
  };

  return updateObject(state, updatedState);
};

const fetchRequestError = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REQUEST_START:
      return fetchRequetStart(state);
    case actionTypes.FETCH_REQUEST_SUCCESS:
      return fetchRequestSuccess(state, action);
    case actionTypes.FETCH_REQUEST_FAIL:
      return fetchRequestError(state, action);
    default:
      return state;
  }
};

export default reducer;
