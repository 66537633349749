import { updateObject } from 'utils/utility';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  token: null,
  userId: null,
  userProfile: null,
  error: null,
  loading: false,
  role: null,
};

const authStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    token: action.token,
    userId: action.userId,
    userProfile: action.userProfile,
    role: action.userProfile.role,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const authLogout = state => {
  return updateObject(state, { token: null, userId: null });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state);
    default:
      return state;
  }
};

export default reducer;
