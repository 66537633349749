import React from 'react';
import { hydrate, render } from 'react-dom';
import './assets/styles/main.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import userManager from './utils/userManager';

const rootElement = document.getElementById('root');

if (window.location.pathname === '/silent-renew') {
  userManager
    .signinSilentCallback({ client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET })
    .then(user => {
      console.log('signinSilentCallback....');
      console.log(user);
    })
    .catch(err => {
      console.log('signinSilentCallback error....');
      console.log(err);
    });
} else if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
