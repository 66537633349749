import * as actionTypes from 'store/actions/actionTypes';
import { updateObject } from 'utils/utility';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const fetchRequestMasterStart = state => {
  return updateObject(state, {
    loading: true,
    data: null,
    error: null,
  });
};

const fetchRequestMasterSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    data: action.payload,
    error: null,
  });
};

const fetchRequestMasterFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    data: null,
    error: action.payload,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REQUEST_MASTER_START:
      return fetchRequestMasterStart(state);
    case actionTypes.FETCH_REQUEST_MASTER_SUCCESS:
      return fetchRequestMasterSuccess(state, action);
    case actionTypes.FETCH_REQUEST_MASTER_FAIL:
      return fetchRequestMasterFail(state, action);
    default:
      return state;
  }
};

export default reducer;
