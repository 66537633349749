import * as actionTypes from 'store/actions/actionTypes';
import { updateObject } from 'utils/utility';

const initialState = {
  loading: false,
  error: null,
};

const initDashboardStart = state => {
  return updateObject(state, { error: null, loading: true });
};

const initDashboardSuccess = state => {
  return updateObject(state, {
    error: null,
    loading: false,
  });
};

const initDashboardFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INIT_DASHBOARD_START:
      return initDashboardStart(state);
    case actionTypes.INIT_DASHBOARD_SUCCESS:
      return initDashboardSuccess(state);
    case actionTypes.INIT_DASHBOARD_FAIL:
      return initDashboardFail(state, action);
    default:
      return state;
  }
};

export default reducer;
