/* eslint-disable no-console */
import React, { Suspense } from 'react';
import { Provider as ReactReduxProvider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';

import userManager from 'utils/userManager';
import { store, history } from 'store/store';
import ErrorBoundary from 'components/ErrorBoundary';
import Loading from 'components/Loading';
import Routes from 'Routes';
import Boot from 'store/boot';

const App = () => (
  <ReactReduxProvider store={store}>
    <OidcProvider userManager={userManager} store={store}>
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Routes history={history} />
        </Suspense>
      </ErrorBoundary>
    </OidcProvider>
  </ReactReduxProvider>
);

Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
