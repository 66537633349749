import React from 'react';

import withPageTitle from 'utils/withPageTitle';

const Loading = () => (
  <div className="loader">
    <div className="loader__warpper">
      <div className="loader__inner" />
      <div className="loader__text">Loading</div>
    </div>
  </div>
);

export default withPageTitle('Loading')(Loading);
