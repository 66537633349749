import * as actionTypes from 'store/actions/actionTypes';
import { updateObject } from 'utils/utility';

const initialState = {
  request: {},
  loading: true,
  error: null,
  formSubmitted: false,
  formLoading: false,
  formError: null,
};

const submitRequestReimburseStart = state => {
  return updateObject(state, { formError: null, formLoading: true });
};

const submitRequestReimburseSuccess = state => {
  return updateObject(state, {
    formLoading: false,
    formSubmitted: true,
  });
};

const submitRequestReimburseFail = (state, action) => {
  return updateObject(state, {
    formError: action.error,
    formLoading: false,
  });
};

const initFormStart = state => {
  return updateObject(state, { loading: true, error: null });
};

const initFormSuccess = (state, action) => {
  return updateObject(state, { loading: false, request: action.data });
};

const initFormFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const deleteRequestReimburseStart = state => {
  return updateObject(state, { formLoading: true, formError: null });
};

const deleteRequestReimburseSuccess = state => {
  return updateObject(state, { formLoading: false, formSubmitted: true });
};

const deleteRequestReimburseFail = (state, action) => {
  return updateObject(state, { formLoading: false, formError: action.error });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUBMIT_REQUEST_REIMBURSE_START:
      return submitRequestReimburseStart(state);
    case actionTypes.SUBMIT_REQUEST_REIMBURSE_SUCCESS:
      return submitRequestReimburseSuccess(state);
    case actionTypes.SUBMIT_REQUEST_REIMBURSE_FAIL:
      return submitRequestReimburseFail(state, action);
    case actionTypes.INIT_REIMBURSE_FORM_START:
      return initFormStart(state);
    case actionTypes.INIT_REIMBURSE_FORM_SUCCESS:
      return initFormSuccess(state, action);
    case actionTypes.INIT_REIMBURSE_FORM_FAIL:
      return initFormFail(state, action);
    case actionTypes.DELETE_REQUEST_REIMBURSE_START:
      return deleteRequestReimburseStart(state);
    case actionTypes.DELETE_REQUEST_REIMBURSE_SUCCESS:
      return deleteRequestReimburseSuccess(state);
    case actionTypes.DELETE_REQUEST_REIMBURSE_FAIL:
      return deleteRequestReimburseFail(state, action);
    case '@@router/LOCATION_CHANGE':
      return initialState;
    default:
      return state;
  }
};

export default reducer;
