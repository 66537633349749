import { store } from './store';
import * as actions from './actions';

const boot = () => {
  return new Promise(() => {
    store.dispatch(actions.getCurrentUser());
  });
};

export default boot;
