import React, { lazy } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';

import * as ROUTES from 'constants/routes';
import { tokenSelector } from 'selectors/auth';

const Login = lazy(() => import('containers/Login'));
const LoginCallback = lazy(() => import('containers/LoginCallback'));
const LogoutCallback = lazy(() => import('containers/LogoutCallback'));
const Dashboard = lazy(() => import('containers/Dashboard'));
const NotFound = lazy(() => import('containers/NotFound'));

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: props.location } }} />
      )
    }
  />
);

const Routes = ({ history, isAuthenticated }) => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path={ROUTES.LANDING} component={props => <Login {...props} />} />
        <Route exact path={ROUTES.LOGIN} component={props => <Login {...props} />} />
        <Route path={ROUTES.LOGIN_CALLBACK} component={props => <LoginCallback {...props} />} />
        <Route path={ROUTES.LOGOUT_CALLBACK} component={props => <LogoutCallback {...props} />} />
        <PrivateRoute
          path={ROUTES.DASHBOARD}
          component={props => <Dashboard {...props} />}
          isAuthenticated={isAuthenticated}
        />
        <Route component={props => <NotFound {...props} />} />
      </Switch>
    </ConnectedRouter>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: tokenSelector(state),
});

export default connect(mapStateToProps)(Routes);
