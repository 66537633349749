import axios from 'axios';
import settings from '../settings';

const axiosInstance = axios.create({
  baseURL: settings.apiUrl,
});

/**
 * Calling api to server in order to verify employee if it includes on Bamboo system
 *
 * @param {import('oidc-client').User} user
 * @return {Promise<void>}
 */
export const verifyEmployee = async user => {
  try {
    // Verify on server-side
    const result = await axiosInstance.post('/v1/auth/google/login', {
      idToken: user.id_token,
      accessToken: user.access_token,
    });

    // Map google profile to Bamboo profile
    user.profile = mergeUserProfile(user.profile, result.data);

    return user;
  } catch (error) {
    throw error;
  }
};

/**
 * Calling api to server in order to log out the current user
 *
 * @param accessToken
 * @return {Promise<void>}
 */
export const logout = async accessToken => {
  try {
    // Logout on server-side
    return await axiosInstance.post(
      '/v1/auth/logout',
      { accessToken: accessToken },
      { headers: { Authorization: `Bearer ${accessToken}` } },
    );
  } catch (error) {
    throw error;
  }
};

/**
 * Merge user profile from google and api
 *
 * @param fromGoogle
 * @param fromApi
 * @return {*&{picture: null}}
 */
const mergeUserProfile = (fromGoogle, fromApi) => {
  return {
    ...fromGoogle,
    ...fromApi,
    picture: null, // because we don't get avatar from BambooHR system
  };
};
