/* eslint-disable no-use-before-define */
import * as actionTypes from 'store/actions/actionTypes';
import { updateObject } from 'utils/utility';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_REPORT_START:
      return fetchReportStart(state);
    case actionTypes.FETCH_REPORT_SUCCESS:
      return fetchReportSuccess(state, action);
    case actionTypes.FETCH_REPORT_FAIL:
      return fetchReportFail(state, action);
    case '@@router/LOCATION_CHANGE':
      return initialState;
    default:
      return state;
  }
};

export default reducer;

function fetchReportStart(state) {
  return updateObject(state, {
    loading: true,
    data: null,
    error: null,
  });
}

function fetchReportSuccess(state, action) {
  return updateObject(state, {
    loading: false,
    data: action.payload,
    error: null,
  });
}

function fetchReportFail(state, action) {
  return updateObject(state, {
    loading: false,
    data: null,
    error: action.payload,
  });
}
