import axios from 'axios';

import settings from './index';
import { store } from '../store/store';
import { checkAuthTimeout } from '../store/actions/auth';

const axiosInstance = axios.create({
  baseURL: settings.apiUrl,
});

axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('accessToken');
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  error => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    switch (error.response.status) {
      case 401:
        store.dispatch(checkAuthTimeout(0));
        break;
      default:
        break;
    }

    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result);
          resolve(Promise.reject(error));
        };
        reader.onerror = () => reject(error);
        reader.readAsText(error.response.data);
      });
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
