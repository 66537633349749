import React, { Component } from 'react';

const withPageTitle = title => WrappedComponent => {
  class PageTitle extends Component {
    componentDidMount() {
      document.title = `${title} - Purchase Order`;
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return PageTitle;
};

export default withPageTitle;
