/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

export const authSelector = state => state.auth;

export const tokenSelector = createSelector(
  authSelector,
  auth => auth.token,
);

export const userIdSelector = createSelector(
  authSelector,
  auth => {
    if (typeof auth.userId === 'string') {
      return parseInt(auth.userId, 10);
    }
    return auth.userId;
  },
);

export const userProfileSelector = createSelector(
  authSelector,
  auth => auth.userProfile,
);

export const rolesSelector = createSelector(
  authSelector,
  auth => auth.role || [],
);
